<template>
  <div>

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Inventory'">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>

    <transition name="fade">
      <div class="content-center">
        <div class="vx-col md:w-4/5 w-full mb-base c-center">
          <vx-card :title="$t('AddInventory')">

            <form-wizard ref="wizard"
                         @on-complete="formComplete"
                         shape="circle"
                         color="rgba(var(--vs-primary), 1)" :title="null" :subtitle="null"
                         :finishButtonText="$t('Submit')">
              <wizard-step
                slot-scope="props"
                slot="step"
                :tab="props.tab"
                :transition="props.transition"
                :index="props.index">
              </wizard-step>
              <tab-content title="Step 1" class="mb-5" :before-change="beforeStep1">

                <div class="vx-row flex">
                  <!-- tab 1 content -->
                  <div class="vx-row vx-col ml-3 flex-1">
                    <div class="vx-col c-center c-center-text" v-if="inventoryCsv">
                      <feather-icon icon="FileTextIcon" svgClasses="feather-csv" size="25"/>
                      <h1>{{inventoryCsv.name}}</h1>
                    </div>
                    <div class="vx-col c-center">
                      <div class="upload-btn-wrapper">
                        <div class="vx-row upload-contents">
                          <feather-icon icon="PlusIcon" svgClasses=""/>
                          <p class="pl-4" style="padding-top: .1rem;">
                            {{$t('UploadCSVFileOnly')}}</p>
                        </div>
                        <input type="file" name="myCsv" accept=".csv,.txt" ref="fileUpload"
                               @change="selectedNewFile"/>
                      </div>
                    </div>
                  </div>

                  <div class="vx-row vx-col ml-3 flex-1" style="place-items: center;">
                    <vs-select autocomplete v-model="delimiter" class="w-full select-large"
                               :label="$t('Delimiter')">
                      <vs-select-item :key="index" :value="item.key" :text="item.title"
                                      v-for="(item,index) in delimiterList" class="w-full"/>
                    </vs-select>
                  </div>
                </div>
              </tab-content>

              <!-- tab 2 content -->
              <tab-content title="Step 2" class="mb-5" :before-change="beforeStep2">
                <div class="vx-row">
                  <!-- tab 2 content -->
                  <div class="vx-row ml-3">
                    <div class="vx-col w-full mt-5">
                      <vs-select autocomplete v-model="poolId" class="w-full select-large"
                                 :label="$t('CallingCardsPool') + '*'"
                                 @input="onProductSelected">
                        <vs-select-item :key="index" :value="item" :text="item.pool_name"
                                        v-for="(item,index) in callingCardsPool" class="w-full"/>
                      </vs-select>
                    </div>
                    <div class="vx-col w-full mt-5">
                      <vs-select autocomplete v-model="batchSeq" class="w-full select-large"
                                 :label="$t('BatchSeq') + '*'">
                        <vs-select-item :key="index" :value="item.sequence" :text="item.parameter"
                                        v-for="(item,index) in sequenceList" class="w-full"/>
                      </vs-select>
                    </div>
                    <div class="vx-col w-full mt-5">
                      <vs-select autocomplete v-model="pinSeq" class="w-full select-large" :label="$t('PinSeq') + '*'">
                        <vs-select-item :key="index" :value="item.sequence" :text="item.parameter"
                                        v-for="(item,index) in sequenceList" class="w-full"/>
                      </vs-select>
                    </div>

<!--                    <div class="vx-col w-full mt-5">-->
<!--                      <vs-input v-validate="'required'" type="number" :label="$t('PinLength') + '*'"-->
<!--                                v-model="pinLength" class="w-full"/>-->
<!--                      <span-->
<!--                        class="text-danger text-sm" v-if="pinLengthError">{{ pinLengthError }}</span>-->
<!--                    </div>-->
                  </div>
                </div>
              </tab-content>

              <!-- tab 3 content -->
              <tab-content title="Step 3" class="mb-5">
                <div class="vx-row">

                  <div class="vx-col justify-center flex w-full" style="text-align: center;">
                    <div class="flex justify-center">
                      <vx-card class="vs-col md:w-auto" style="display: flex" card-background="danger" content-color="#FFFFFF">
                        <feather-icon icon="XIcon"></feather-icon>
                        <p>{{ $t('Invalid') }} <b>{{ invalidPins }}</b> {{ $t('pins') }}</p>
                      </vx-card>
                      <div class="px-4"></div>
                      <vx-card class="vs-col md:w-auto" style="display: flex" card-background="success" content-color="#FFFFFF">
                        <feather-icon icon="CheckIcon"></feather-icon>
                        <p>{{ $t('Valid') }} <b>{{ validPins }}</b> {{ $t('pins') }}</p>
                      </vx-card>
                    </div>
                  </div>
                  <div class="vx-col py-5 justify-center flex w-full" style="text-align: center;" v-if="validPins <= 0">
                    <h5>{{ $t('NoPinsToImported') }}</h5>
                  </div>
                  <div class="vx-col w-full mt-5" v-if="validPins > 0">
                    <div class="vx-col w-full mt-5">
                      <vs-select autocomplete v-model="supplier" class="w-full select-large"
                                 :label="$t('Supplier')"
                                 @input="onSupplierSelected">
                        <vs-select-item :key="index" :value="item.id"
                                        :text="item.full_name" v-for="(item,index) in suppliers"
                                        class="w-full"/>
                      </vs-select>
                    </div>
                    <div class="vx-col w-full mt-5">
                      <vs-input v-validate="'required'" type="number" :label="$t('Discount')"
                                v-model="discount" class="w-full"/>
                    </div>
                  </div>
                </div>
              </tab-content>

              <!--              Custom Footer In Tab-->
              <template slot="footer" slot-scope="props">
                <div class="wizard-footer-left">
                  <!--                  <vs-button v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</vs-button>-->
                </div>
                <div class="wizard-footer-right">

                  <vs-button v-if="!props.isLastStep" @click.native="props.nextTab()"
                             class="wizard-footer-right" :style="props.fillButtonStyle">
                    {{ $t('Next') }}
                  </vs-button>

                  <vs-button v-if="props.isLastStep && validPins > 0" @click.native="formComplete"
                             class="wizard-footer-right finish-button"
                             :style="props.fillButtonStyle"> {{props.isLastStep ? $t('Done') : $t('Next')}}
                  </vs-button>
                  <vs-button  @click.native="cancelForm"
                              class="wizard-footer-right finish-button mx-2" color="danger"
                              :style="props.fillButtonStyle"> {{ $t('Cancel') }}
                  </vs-button>

                </div>
              </template>
            </form-wizard>

          </vx-card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'AddInventory',
  components: {
    'v-select': vSelect,
  },
  computed: {
    callingCardsPool() {
      return this.$store.state.callingCards.callingCardsPool
    },
    suppliers() {
      return this.$store.state.suppliers.suppliers
    },
    validateStep2() {
      return !this.errors.any() && this.poolId !== null && this.pinSeq !== null && this.batchSeq !== null
    },
    validateStep3() {
      return !this.errors.any() && this.supplier !== '' && this.discount !== null
        // && this.pinLength !== null
    },
    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep
      }
      return false
    },
  },
  data() {
    return {
      pinLengthError: '',
      discount: '',
      // pinLength: '',
      supplier: '',
      poolId: '',
      inventoryCsv: null,
      delimiter: ';',
      delimiterList: [
        {
          title: 'Semicolon (;)',
          key: ';',
        },
        {
          title: 'Tab Space',
          key: 't',
        },
        {
          title: 'Colon (:)',
          key: ':',
        },
        {
          title: 'Comma (,)',
          key: ',',
        },
      ],
      batchNo: null,
      sequenceList: [],

      pinSeq: null,
      batchSeq: null,

      validPins: '',
      invalidPins: '',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Inventory', i18n: 'Inventory', url: '/inventory/view' },
        { title: 'Add Inventory', i18n: 'AddInventory', active: true },
      ],
    }
  },
  methods: {
    onSupplierSelected() {
      const payload = {
        'poolId': this.poolId.pool_id,
        'supplierId': this.supplier,
      }
      this.$store.dispatch('callingCards/fetchSupplierDiscount', payload).then((data) => {
        this.$vs.loading.close()
        if (data.message) {

        } else {
          this.discount = data.discount
        }
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.message ? error.message : error.detail,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
    onProductSelected() {
      this.configuration = ''
      // this.pinLength = this.poolId.pin_length | 10
    },
    async selectedNewFile(e) {
      this.inventoryCsv = this.$refs.fileUpload.files[0]
    },
    async beforeStep2() {
      if (this.validateStep2) {
        const payload = {
          poolId: this.poolId.pool_id,
          pinSeq: this.pinSeq,
          batchNo: this.batchNo,
          batchSeq: this.batchSeq,
        }
        this.$vs.loading()
        let data;
        try {
          data = await this.$store.dispatch('callingCards/uploadStepTwo', payload)
        } catch(error) {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        }
        this.$vs.loading.close()
        if (data) {
          this.$vs.loading.close()
          this.validPins = data.sucessful_pins
          this.invalidPins = data.unsucessful_pins
          // console.log(data.pin_length != this.pinLength)
          // if (data.pin_length != this.pinLength) {
          //
          //   this.pinLengthError = 'Product pin length ' + this.product.pin_length + ' didn\'t match with file pin length ' + data.pin_length
          //
          //   // await this.$vs.notify({
          //   //   position: 'top-center',
          //   //   title: 'Error',
          //   //   text: this.pinLengthError,
          //   //   iconPack: 'feather',
          //   //   icon: 'icon-alert-circle',
          //   //   color: 'danger',
          //   // })
          //   return false
          // }
          // if (data.pin_length == this.pinLength) {
          //   this.pinLengthError = ''
          //   this.$vs.notify({
          //     position: 'top-center',
          //     title: 'Success',
          //     text: data.message,
          //     iconPack: 'feather',
          //     icon: 'icon-check-circle',
          //     color: 'success',
          //   })
          //   return true
          // }
          return true;

        } else {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        }
      } else {
        return false
      }
    },
    async beforeStep1() {
      if (this.inventoryCsv) {
        const payload = {
          inventoryCsv: this.inventoryCsv,
          delimiter: this.delimiter,
        }
        this.$vs.loading()
        const data = await this.$store.dispatch('callingCards/uploadCSVFile', payload).catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        })
        if (data) {
          this.$vs.loading.close()
          this.batchNo = data.import_batch
          this.sequenceList = data.parameters
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          return true
        }
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      }
      return false
    },
    cancelForm() {
      this.$router.push({ name: 'inventory-pool' })
    },
    formComplete() {
      if (this.validateStep3) {
        const payload = {
          supplierId: this.supplier,
          discount: this.discount,
          batchNo: this.batchNo,
        }
        this.$vs.loading()
        this.$store.dispatch('callingCards/uploadStepThree', payload).then((data) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          this.$router.push({ name: 'inventory-pool' })
        }).catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        })
      }
      return false
    },
  },
  created() {
    this.$vs.loading()
    this.$store.dispatch('callingCards/fetchCallingCardPools', {})
      .then((data) => {
        this.$vs.loading.close()
        this.errorFetching = false
      })
      .catch((err) => {
        this.$vs.loading.close()
        this.errorFetching = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    const filter = {}
    this.$store.dispatch('suppliers/fetchSuppliers', {
      isMore: false, filter, search: null, totalItems: 100,
    })
      .then((data) => {
        this.$vs.loading.close()
        this.errorFetching = false
      })
      .catch((err) => {
        this.$vs.loading.close()
        this.errorFetching = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },
}
</script>

<style scoped>
  .c-center {
    margin: auto;
  }

  .c-center-text {
    text-align: center;
  }

  .feather-csv {
    width: 50px;
    height: 50px;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
  }

  .p-type {
    padding: 0 1rem;
    font-size: 10px;
  }

  .p-type-select {
    padding: 0 0 0 1rem;
  }

  .upload-btn-wrapper {
    margin: 1rem;
    position: relative;
    display: flex;
  }

  .upload-contents {
    border: 1px dashed #00000040;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
    color: #2C2C2C;
    background-color: white;
    padding: 30px 30px;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    margin: auto;
  }

  .upload-btn-wrapper-denomination {
    position: absolute;
  }

  .upload-contents-denomination {
    padding: 5px;
  }

  .upload-btn-wrapper input[type=file] {
    position: absolute;
    margin: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  .p-image {
    width: 90%;
    object-fit: cover;
    max-height: 200px;
    margin: 1rem;
  }

  .p-image-denomination {
    width: 50%;
  }
</style>
